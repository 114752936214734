<template>
  <!-- HERO SECTION -->
  <div class="relative pt-16 pb-32 overflow-hidden">
  <div aria-hidden="true" class="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
  <div class="relative">
    <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:grid-flow-col-dense lg:gap-24">
      <div class="col-span-2 px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
        <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-indigo-600 sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
          <span class="block font-thin text-indigo-600">Website design & development </span>
          <span class="block  text-gray-900">that just works</span>
        </h1>
        <p class="mt-3 text-base font-thin text-gray-900 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          Websites can often be more of a problem than a solution. We design award-winning websites which just work.
        </p>
        <div class="mt-16">
          <router-link to="/contact" class="px-8 py-3 border border-transparent
            text-base font-medium rounded-md text-white bg-indigo-600
            hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
              Get started
            </router-link>
        </div>
      </div>
      <div class="mt-12 sm:mt-16 lg:mt-0">
        <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">

          <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
          src="../assets/website-hero.png" alt="Website design and development" />
        </div>
      </div>
    </div>
  </div>
</div>

  <!-- Feature section -->
  <div class="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
    <div class="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
      <div>
        <h2 class="text-base font-semibold tracking-wider text-indigo-600 uppercase">Beautiful customer experiences</h2>
        <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          which delight and deliver business results.
        </p>
        <p class="mt-5 max-w-prose mx-auto  text-gray-500">
          Whether your business is building a brand new website, or undertaking an overhaul of its digital presence, your motivations will likely be to
          <span class="font-semibold text-indigo-600">improve design, enhance experience and maximise performance. </span>
        </p>

        <p class="mt-5 max-w-prose mx-auto  text-gray-500">
          From <router-link to="/work" class="font-semibold text-indigo-600">beautifully crafted web design</router-link> to present your brand in the best light; to <router-link to="/work" class="font-semibold text-indigo-600">eCommerce websites</router-link> geared to transact and perform under pressure, we've been there before.
        </p>
      </div>
    </div>
  </div>

<div class="relative pt-16 pb-32 overflow-hidden">
  <div aria-hidden="true" class="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-200" />
  <div class="relative">
    <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
      <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
        <div>
          <div>
            <span class="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
              <InboxIcon class="h-6 w-6 text-white" aria-hidden="true" />
            </span>
          </div>
          <div class="mt-6">
            <h2 class="text-3xl font-thin font-extrabold tracking-tight text-gray-900 block inline">
              Website
            </h2>
            <p class="mt-2 text-3xl font-extralight text-gray-900 tracking-tight block inline">
              design and development made simple.
            </p>
            <p class="mt-4 text-lg text-gray-500  font-light">
              We build websites which just work. Simplicity doesn't mean basic, however. Our UX-led approach means customer journeys and business outcomes are at the forefront of our thinking, whilst integrations and functionality are kept seamless.
            </p>
          </div>
        </div>
        <div class="mt-6">
            <router-link to="/contact" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700">
              Let's Talk
            </router-link>
        </div>
      </div>
      <div class="mt-12 sm:mt-16 lg:mt-0">
        <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
          <img class="w-full rounded-xl shadow-xl ring-1 ring-black left-0 shadow-xl ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
          src="../assets/landing_page.jpg" alt="Website design and development" />
        </div>
      </div>
    </div>
  </div>
  <div class="mt-24">
    <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
      <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
        <div>
          <div>
            <span class="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600">
              <SparklesIcon class="h-6 w-6 text-white" aria-hidden="true" />
            </span>
          </div>
          <div class="mt-6">
            <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 block inline">
              eCommerce Websites
            </h2>
            <h2 class="text-3xl font-extralight tracking-tight text-gray-900 block inline">
              built to deliver revenue.
            </h2>
            <p class="mt-4 text-lg font-light  text-gray-500">
              Whatever the platform, your eCommerce website should do so much more than just transact. Nurturing your customers from being just aware of your brand, to true product advocates is part of their experience and vital in driving repeat custom and advocacy. Wonderful eCommerce websites just work, for the company and the customer.
            </p>
            <div class="mt-6">
              <router-link to="/contact" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700">
                Get started
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
        <div class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
          <img class="w-full rounded-xl shadow-xl ring-1 ring-black right-0 shadow-xl ring-opacity-5 lg:absolute mt-24 lg:h-3/4 lg:w-auto lg:max-w-none"
          src="../assets/ecommerce_hero.jpg" alt="eCommerce Websites" />
        </div>
      </div>
    </div>
  </div>
</div>

<FindOutAboutUS />
</template>

<script>

import { InboxIcon, SparklesIcon } from '@heroicons/vue/solid';
import FindOutAboutUS from '@/components/FindOutAboutUS.vue';

const standardWebsiteFeaturesIncludes = [
  'Modern beatiful website',
  'UX-led design and optimised user journeys that convert.',
  'Modern Web Interfaces - The Progressive Framework',
  'Free Custom Domain* (.co.uk or .com)',
  'Unlimited Revisions',
  'SSL Security',
  'SEO Features for Site Visibility',
  'Fully Mobile & Tablet Responsive',
  'Custom and secure business email',
  'Google Workspace (Business Starter)',
];

export default {
  components: {
    InboxIcon,
    SparklesIcon,
    FindOutAboutUS,
  },
  setup() {
    return {
      standardWebsiteFeaturesIncludes,
    };
  },
};

</script>
